import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './redux/store';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import './i18n';
import './css/style.css';
import 'react-app-polyfill/ie9';
import React from 'react';
import TotalApp from './routes/TotalApp';
import Persoonsgegevens from './routes/Persoonsgegevens';
import Onderdelen from './routes/Onderdelen';
import Tand from './routes/Tand';
import Thankyou from './routes/Thankyou';
import Spoed from './routes/Spoed';
import PijnTand from './routes/PijnTand';
import Verstandskies from './routes/Verstandskies';
import Tandvlees from './routes/Tandvlees';
import HogeVulling from './routes/HogeVulling';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import Implantaat from './routes/Implantaat';
import Prothese from './routes/Prothese';
import BeugelSpalk from './routes/BeugelSpalk';
import Anders from './routes/Anders';
import AfgebrokenTand from './routes/AfgebrokenTand';
import KroonTand from './routes/KroonTand';
import Foutmelding from './routes/FoutMelding';
import WieTandarts from './routes/WieTandarts';
import RootIndex from 'routes/RootIndex';
import LosseTand from 'routes/LosseTand';

const has_iframe_param = new URLSearchParams(window.location.search).has('iframe');

const theme = createTheme(({
  palette: {
    background: {
      default: has_iframe_param ? "000" : "#eaeff1"
    },
    primary: {
      // main: '#26525f',
      main: '#1e4a71',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fb882e',
    },
    error: {
      main: "#ff604f"
    },
  },
}));

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}
if (window.location.host.split(':')[0] !== "localhost") {
  console.log = () => { }
  console.error = () => { };
  console.debug = () => { }
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme} >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<TotalApp />}>

                <Route index element={<RootIndex />} />
                <Route path="afgebroken_tand" element={<AfgebrokenTand />} />
                <Route path="losgekomen_brug_kroon" element={<KroonTand />} />
                <Route path="pijn_tand" element={<PijnTand />} />
                <Route path="hoge_vulling" element={<HogeVulling />} />
                <Route path="losse_tand" element={<LosseTand />} />
                <Route path="onderdelen" element={<Onderdelen />} />
                <Route path="persoonsgegevens" element={<Persoonsgegevens />} />
                <Route path="tand" element={<Tand />} />
                <Route path="verstandskies" element={<Verstandskies />} />
                <Route path="tandvlees" element={<Tandvlees />} />
                <Route path="implantaat" element={<Implantaat />} />
                <Route path="beugel_spalk" element={<BeugelSpalk />} />
                <Route path="prothese" element={<Prothese />} />
                <Route path="anders" element={<Anders />} />
                <Route path="fout" element={<Foutmelding />} />

                <Route path="spoed" element={<Spoed />} />
                <Route path="thankyou" element={<Thankyou />} />

              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  </Provider>
  ,
  document.getElementById('root')
);



