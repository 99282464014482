import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TriadentStepper from '../components/TriadentStepper';

export default function Foutmelding() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <TriadentStepper onderdeel="klacht" />
            <Typography variant="h5" gutterBottom>
                {t('Er is iets fout gegaan met het systeem')}.
            </Typography>
            <Typography variant="subtitle1">

                {t('Neem contact op met de praktijkassistente of probeer het later opnieuw.')}
            </Typography>
        </React.Fragment>
    );
}


