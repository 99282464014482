import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { maxWidth } from "@mui/system";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addFormData } from "../redux/actions";
import Submit from "../components/Submit";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TriadentStepper from "../components/TriadentStepper";
import { ErrorMessage } from "@hookform/error-message";
import BackSubmit from "../components/BackSubmit";
import Pagination from "../components/Pagination";
import OnNextSubmit from "../components/OnNextSubmit";
import { OnLastSubmitKlacht } from "../components/OnLastSubmit";
import { useOutletContext } from "react-router-dom";
import { praktijkinfo } from "../types/praktijkinfo";
import { radiogroep_util } from "../utils/radiogroep_util";
import { extra_klacht_data, klacht_data } from "../types/klacht_data";
import { klachtCompProps } from "../types/redux";
import { extra_notities } from "components/extra_notities";

let loading = false;
const setLoading = (bool: boolean) => {
  loading = bool;
};

interface Verstandskies extends klachtCompProps {}

const Verstandskies: React.FC<Verstandskies> = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: props.formData,
  });

  const { t, i18n } = useTranslation();

  const locaties = [
    t("rechtsboven"),
    t("linksboven"),
    t("linksonder"),
    t("rechtsonder"),
    t("op onbekende plek"),
  ];

  const pijnstillers = [t("ibuprofen"), t("paracetamol"), t("anders")];

  const radiogroep = radiogroep_util(errors, control);

  const onLastSubmit = OnLastSubmitKlacht(setLoading, null, "Verstandskies");
  const extra_data: extra_klacht_data = { klacht: "Verstandskies" };
  const onNextSubmit = OnNextSubmit(
    props.dispatch,
    extra_data,
    "/persoonsgegevens"
  );
  const praktijkInfo: praktijkinfo = useOutletContext();
  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];
  const onSubmit = onLastSubmit;

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="klacht" />
      <ErrorMessage
        errors={errors}
        name={Object.keys(errors)[0]}
        render={({ message }) => (
          <p className={"error-message"}>
            {Object.keys(errors).map((e) => t(e)).length == 1
              ? `${t("Het veld")} ${
                  Object.keys(errors).map((e) => t(e))[0]
                } ${t("is verplicht")}`
              : `${t("De velden")} ${Object.keys(errors)
                  .map((e) => t(e))
                  .join(", ")
                  .replaceAll("_", " ")
                  .replace(/,(?=[^,]*$)/, " en")} ${t("zijn verplicht")}`}
          </p>
        )}
      />
      <Typography>{t("Vul de volgende gegevens in")}:</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={-1} spacing={3}>
          <Grid item xs={12}>
            {/* <FormControl fullWidth variant="standard" error={!!errors.tand_locatie}>
                            <InputLabel htmlFor="age-native-simple">{t("Wat is de locatie van de verstandskies")}?</InputLabel>
                            <Select
                                // displayEmpty
                                inputProps={register('tand_locatie', { required: true })}
                                native

                            >
                                <option value={"Rechtsboven"}>{t("Rechtsboven")}</option>
                                <option value={"Linksboven"}>{t("Linksboven")}</option>
                                <option value={"Linksonder"}>{t("Linksonder")}</option>
                                <option value={"Rechtsonder"}>{t("Rechtsonder")}</option>
                                <option value={"Op onbekende plek"}>{t("Onbekend")}</option>


                            </Select>
                        </FormControl> */}

            <FormGroup>
              <InputLabel htmlFor="age-native-simple">
                {t("Wat is de locatie van de verstandskies")}?
              </InputLabel>

              <div>
                {[
                  "rechtsboven",
                  "linksboven",
                  "linksonder",
                  "rechtsonder",
                  "op onbekende plek",
                ].map((value, idx) => {
                  return (
                    <FormControlLabel
                      key={value}
                      control={
                        <Controller
                          // @ts-ignore
                          name={value + "_locatie"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => <Checkbox {...field} />}
                        />
                      }
                      label={
                        locaties[idx].charAt(0).toUpperCase() +
                        locaties[idx].slice(1)
                      }
                    />
                  );
                })}
              </div>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "pijn",
              t("Heeft u pijn?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "volledig_doorgekomen",
              t("Is uw verstandkies volledig doorgekomen?")
            )}
          </Grid>

          {watch("pijn") == "nee" && (
            <Grid item xs={12}>
              <FormControl
                fullWidth
                component="fieldset"
                error={!!errors.klacht_uitleg}
              >
                <FormLabel sx={{ mb: 1 }} component="legend">
                  {t("Kunt u de klacht zo goed mogelijk omschrijven?")}
                </FormLabel>

                <TextField
                  placeholder={t("Licht toe") + "..."}
                  InputProps={register("klacht_uitleg", { required: true })}
                  multiline
                  // rows={2}
                  maxRows={4}
                />
              </FormControl>
            </Grid>
          )}

          {watch("pijn") == "ja" && (
            <Grid item xs={12}>
              <FormControl
                fullWidth
                component="fieldset"
                error={!!errors.tijd_geleden}
              >
                <FormLabel sx={{ mb: 2 }} component="legend">
                  {t(
                    "Sinds wanneer heeft u last van een pijnlijke verstandskies?"
                  )}
                </FormLabel>

                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <TextField
                      variant="standard"
                      type="number"
                      InputProps={register("tijd_geleden", { required: true })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl variant="standard" fullWidth>
                      <Select
                        inputProps={register("tijd_eenheid", {
                          required: true,
                        })}
                        native
                      >
                        <option value={"dagen"}>{t("dagen")}</option>
                        <option value={"weken"}>{t("weken")}</option>
                        <option value={"maanden"}>{t("maanden")}</option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          )}

          {watch("pijn") == "ja" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("ja"), t("nee")],
                "pijnstillers",
                t("Heeft u pijnstillers gehad?")
              )}
            </Grid>
          )}

          {watch("pijn") == "ja" && watch("pijnstillers") == "ja" && (
            <Grid mt={-1} item xs={12}>
              <FormGroup>
                <FormLabel>{t("Welke pijnstiller(s)?")}</FormLabel>

                <div>
                  {["ibuprofen", "paracetamol", "anders"].map((value, idx) => {
                    return (
                      <FormControlLabel
                        key={value}
                        control={
                          <Controller
                            // @ts-ignore
                            name={value + "_pijnstiller"}
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => <Checkbox {...field} />}
                          />
                        }
                        label={
                          pijnstillers[idx].charAt(0).toUpperCase() +
                          pijnstillers[idx].slice(1)
                        }
                      />
                    );
                  })}
                </div>
              </FormGroup>
            </Grid>
          )}
          {watch("pijn") == "ja" &&
            watch("pijnstillers") == "ja" &&
            watch("anders_pijnstiller") && (
              <Grid mt={-4} item xs={12}>
                <TextField
                  fullWidth
                  error={!!errors.andere_pijnstiller}
                  variant="standard"
                  label={t("Andere pijnstiller(s)")}
                  InputProps={register("andere_pijnstiller", {
                    required: false,
                  })}
                />
              </Grid>
            )}

          {watch("pijn") == "ja" && watch("pijnstillers") == "ja" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("ja"), t("nee")],
                "helpen_pijnstillers",
                t("Helpen de pijnstillers?")
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "wang",
              t("Is er sprake van een dikke wang?")
            )}
          </Grid>
          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "koorts",
              t("Heeft u koorts?")
            )}
          </Grid>

          {is_spoed_praktijk && extra_notities(t, register, errors)}
        </Grid>

        <Pagination loading={loading} />
      </form>
    </React.Fragment>
  );
};

// export default KroonTand;
function mapStateToProps(state: any) {
  console.log(state, typeof state);
  return { formData: state.formData };
}

export default connect(mapStateToProps)(Verstandskies);
