import { Stepper, Step, StepLabel, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { praktijkinfo_load } from '../types/praktijkinfo';

interface props {
    onderdeel: string;
}


export default function TriadentStepper({ onderdeel }: props) {

    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const small_screen = useMediaQuery(theme.breakpoints.down('sm'));
    const praktijkInfo: praktijkinfo_load = useOutletContext();

    if (!praktijkInfo) {
        return <></>
    }
    var is_spoed_praktijk = praktijkInfo['is_spoed_praktijk'];
    const steps = is_spoed_praktijk ? [t('Type klacht'), t('Klacht informatie'), t('Afspraak aanvragen')] : [t('Persoonsgegevens'), t('Type klacht'), t('Afspraak aanvragen')];

    let activestep;

    if (is_spoed_praktijk && onderdeel === "klacht") {
        activestep = 1;
    }
    if (!is_spoed_praktijk && onderdeel === "klacht") {
        activestep = 2;
    }
    if (is_spoed_praktijk && onderdeel === "klacht_onderdeel") {
        activestep = 0;
    }
    if (!is_spoed_praktijk && onderdeel === "klacht_onderdeel") {
        activestep = 1;
    }
    if (is_spoed_praktijk && onderdeel === "gegevens") {
        activestep = 2;
    }
    if (!is_spoed_praktijk && onderdeel === "gegevens") {
        activestep = 0;
    }
    if (onderdeel === "bevestiging") {
        activestep = 3;
    }

    return (
        <React.Fragment>
            <Stepper sx={{ mt: 3, mb: 5 }} activeStep={activestep} >
                {
                    steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{small_screen ? "" : label}</StepLabel>
                        </Step>
                    ))}
            </Stepper>
        </React.Fragment>
    )

}
