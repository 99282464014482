import React from 'react';

export function uploadPhoto_utils(setFoto: React.Dispatch<React.SetStateAction<string | null | undefined>>) {
    return (e: React.FormEvent<HTMLInputElement>) => {
        let files = (e.target as HTMLInputElement).files;
        if (files) {
            setFoto(URL.createObjectURL(files[0]));
        }
    };
}
