import { persoonsgegevens } from "./../types/klacht_data";
import { useLocation, useNavigate } from "react-router-dom";
import { addFormData } from "redux/actions";

export default function OnNextSubmit(
  dispatch: any,
  extra_data: any,
  next_page_link: string,
  as_persoonsgegevens = false
) {
  const navigate = useNavigate();
  const location = useLocation();
  return async (data: any) => {
    if (data["spoed"] === "ja") {
      dispatch(addFormData(data));
      // navigate("/spoed" + location.search);
      navigate("/persoonsgegevens" + location.search);
    } else {
      var total_data = { ...data, ...extra_data };
      if (as_persoonsgegevens) {
        total_data = { persoonsgegevens: data };
      }
      dispatch(addFormData(total_data));
      navigate(next_page_link + location.search);
    }
  };
}
