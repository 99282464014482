import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from 'locales/en/translation.json';
import translationNL from 'locales/nl/translation.json';
import translationUK from 'locales/uk/translation.json';
import translationAR from 'locales/ar/translation.json';
import translationDE from 'locales/de/translation.json';



const resources = {
  en: {
    translation: translationEN
  },
  nl: {
    translation: translationNL
  },
  ua: {
    translation: translationUK
  },
  ar: {
    translation: translationAR
  },
  de: {
    translation: translationDE
  }
};

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18n

  .use(LanguageDetector)

  .use(initReactI18next)

  .init({
    resources,
    fallbackLng: "nl",
    returnObjects: true,
    detection: options,
    debug: true,
    supportedLngs: ['en', 'nl', 'ar', 'ua', 'de'],
    // Remove lng if you want to infer language from browser
    lng: "nl",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });


export default i18n;
