import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { maxWidth } from "@mui/system";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addFormData } from "../redux/actions";
import Submit from "../components/Submit";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TriadentStepper from "../components/TriadentStepper";
import QueryLink from "../components/QueryLink";
import { ErrorMessage } from "@hookform/error-message";
import BackSubmit from "../components/BackSubmit";
import { OnLastSubmitKlacht } from "../components/OnLastSubmit";

import OnNextSubmit from "../components/OnNextSubmit";
import Pagination from "../components/Pagination";
import { useOutletContext } from "react-router-dom";
import { klachtCompProps } from "../types/redux";
import { radiogroep_util } from "../utils/radiogroep_util";
import { extra_klacht_data } from "../types/klacht_data";
import { praktijkinfo } from "../types/praktijkinfo";
import { extra_notities } from "components/extra_notities";
let loading = false;
const setLoading = (bool: boolean) => {
  loading = bool;
};

interface BeugelSpalkProps extends klachtCompProps {}

const BeugelSpalk: React.FC<BeugelSpalkProps> = (props) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: props.formData,
  });
  const { t, i18n } = useTranslation();

  const radiogroep = radiogroep_util(errors, control);

  const onLastSubmit = OnLastSubmitKlacht(setLoading, null, "Beugel of spalk");
  const extra_data: extra_klacht_data = { klacht: "Beugel of spalk" };
  const onNextSubmit = OnNextSubmit(
    props.dispatch,
    extra_data,
    "/persoonsgegevens"
  );
  const praktijkInfo: praktijkinfo = useOutletContext();
  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];
  const onSubmit = onLastSubmit;

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="klacht" />
      <ErrorMessage
        errors={errors}
        name={Object.keys(errors)[0]}
        render={({ message }) => (
          <p className={"error-message"}>
            {Object.keys(errors).map((e) => t(e)).length == 1
              ? `${t("Het veld")} ${
                  Object.keys(errors).map((e) => t(e))[0]
                } ${t("is verplicht")}`
              : `${t("De velden")} ${Object.keys(errors)
                  .map((e) => t(e))
                  .join(", ")
                  .replaceAll("_", " ")
                  .replace(/,(?=[^,]*$)/, " en")} ${t("zijn verplicht")}`}
          </p>
        )}
      />
      <Typography>{t("Vul de volgende gegevens in")}:</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid mt={-1} container spacing={3}>
          <Grid item xs={12}>
            {radiogroep(
              ["beugel", "spalk"],
              [t("beugel"), t("spalk")],
              "beugel_spalk",
              t("Gaat het om uw beugel of uw spalk?")
            )}
          </Grid>

          {watch("beugel_spalk") === "beugel" && (
            <Grid item xs={12}>
              {radiogroep(
                ["aligner", "metalen"],
                [
                  t("aligner (plastic bitjes)"),
                  t("metalen_beugel (bijv. slotjes beugel)"),
                ],
                "wat_voor_beugel",
                t("Wat voor beugel is het")
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            {radiogroep(
              ["boven", "onder"],
              [t("boven"), t("onder")],
              "welke_spalk",
              t("Om welke beugel of spalk gaat het?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "spalk_los",
              t("Zit de beugel of spalk los?")
            )}
          </Grid>

          {watch("wat_voor_beugel") === "metalen" &&
            watch("spalk_los") === "ja" && (
              <Grid item xs={12}>
                {radiogroep(
                  ["ja", "nee"],
                  [t("ja"), t("nee")],
                  "is_slotje_los",
                  t("Is er sprake van een loszittend slotje?")
                )}
              </Grid>
            )}

          {watch("beugel_spalk") === "spalk" && watch("spalk_los") === "ja" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("helemaal los"), t("gedeeltelijk los")],
                "helemaal_los",
                t("Zit de spalk helemaal of gedeeltelijk los?")
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "scherp",
              t("Voelt de beugel of spalk scherp?")
            )}
          </Grid>

          {is_spoed_praktijk && extra_notities(t, register, errors)}
        </Grid>

        <Pagination loading={loading} />
      </form>
    </React.Fragment>
  );
};

// export default KroonTand;
function mapStateToProps(state: any) {
  console.log("init with formData:");
  console.log(state.formData);
  return { formData: state.formData };
}

export default connect(mapStateToProps)(BeugelSpalk);
