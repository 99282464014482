import {
  Box,
  Button,
  FormControl,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { maxWidth } from "@mui/system";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addFormData } from "../redux/actions";
import Submit from "../components/Submit";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TriadentStepper from "../components/TriadentStepper";
import { ErrorMessage } from "@hookform/error-message";
import BackSubmit from "../components/BackSubmit";
import Pagination from "../components/Pagination";
import OnNextSubmit from "../components/OnNextSubmit";
import { OnLastSubmitKlacht } from "../components/OnLastSubmit";
import { useOutletContext } from "react-router-dom";
import { extra_klacht_data, klacht_data } from "../types/klacht_data";
import { uploadPhoto_utils } from "../utils/uploadPhoto_utils";
import { praktijkinfo } from "../types/praktijkinfo";
import { radiogroep_util } from "../utils/radiogroep_util";
import { klachtCompProps } from "../types/redux";
import { photoBox } from "utils/photoBox";
import { extra_notities } from "components/extra_notities";

let loading = false;
const setLoading = (bool: boolean) => {
  loading = bool;
};

interface TandvleesProps extends klachtCompProps {}

const Tandvlees = (props: TandvleesProps) => {
  const navigate = useNavigate();
  const [foto, setFoto] = React.useState(props.formData.foto);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: props.formData,
  });

  const { t, i18n } = useTranslation();
  const locaties = [
    t("rechtsboven"),
    t("linksboven"),
    t("linksonder"),
    t("rechtsonder"),
    t("middenonder"),
    t("middenboven"),
    t("op onbekende plek"),
  ];

  const uploadPhoto = uploadPhoto_utils(setFoto);

  const radiogroep = radiogroep_util(errors, control);

  const onLastSubmit = OnLastSubmitKlacht(setLoading, null, "Tandvlees");
  const extra_data: extra_klacht_data = { klacht: "Tandvlees" };
  if (foto) {
    extra_data["foto_blob_url"] = foto;
  }
  const onNextSubmit = OnNextSubmit(
    props.dispatch,
    extra_data,
    "/persoonsgegevens"
  );
  const praktijkInfo: praktijkinfo = useOutletContext();
  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];
  const onSubmit = onLastSubmit;

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="klacht" />
      <ErrorMessage
        errors={errors}
        name={Object.keys(errors)[0]}
        render={({ message }) => (
          <p className={"error-message"}>
            {Object.keys(errors).map((e) => t(e)).length == 1
              ? `${t("Het veld")} ${
                  Object.keys(errors).map((e) => t(e))[0]
                } ${t("is verplicht")}`
              : `${t("De velden")} ${Object.keys(errors)
                  .map((e) => t(e))
                  .join(", ")
                  .replaceAll("_", " ")
                  .replace(/,(?=[^,]*$)/, " en")} ${t("zijn verplicht")}`}
          </p>
        )}
      />
      <Typography>{t("Vul de volgende gegevens in")}:</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={-1} spacing={3}>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel htmlFor="age-native-simple">
                {t("Wat is de locatie van de klacht")}?
              </InputLabel>

              <div>
                {[
                  "rechtsboven",
                  "linksboven",
                  "linksonder",
                  "rechtsonder",
                  "middenonder",
                  "middenboven",
                  "op onbekende plek",
                ].map((value, idx) => {
                  return (
                    <FormControlLabel
                      key={value.toUpperCase()}
                      control={
                        <Controller
                          // @ts-ignore
                          name={value + "_locatie"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => <Checkbox {...field} />}
                        />
                      }
                      label={
                        locaties[idx].charAt(0).toUpperCase() +
                        locaties[idx].slice(1)
                      }
                    />
                  );
                })}
              </div>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "pijn",
              t("Heeft u pijn?")
            )}
          </Grid>

          {watch("pijn") == "ja" && (
            <Grid item xs={12}>
              <FormControl
                fullWidth
                component="fieldset"
                error={!!errors.tijd_geleden}
              >
                <FormLabel sx={{ mb: 2 }} component="legend">
                  {t("Sinds wanneer heeft u last van pijnlijk tandvlees?")}
                </FormLabel>

                <Grid container spacing={1}>
                  <Grid item xs={4} md={3}>
                    <TextField
                      variant="standard"
                      type="number"
                      InputProps={register("tijd_geleden", { required: true })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={4} md={2}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{ minWidth: 120 }}
                    >
                      <Select
                        inputProps={register("tijd_eenheid", {
                          required: true,
                        })}
                        native
                      >
                        <option value={"dagen"}>{t("dagen")}</option>
                        <option value={"weken"}>{t("weken")}</option>
                        <option value={"maanden"}>{t("maanden")}</option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "bloeden",
              t("Bloedt uw tandvlees bij stokeren of flossen?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "tandvlees_behandelingen",
              t("Heeft u ooit behandelingen voor uw tandvlees ontvangen?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "tandvleesproblematiek_familie",
              t("Is er sprake van tandvleesproblematiek binnen uw familie?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "vreemd_plekje",
              t("Is er sprake van een vreemd plekje?")
            )}
          </Grid>

          {is_spoed_praktijk && extra_notities(t, register, errors)}

          {photoBox(t, foto, uploadPhoto)}
        </Grid>

        <Pagination loading={loading} />
      </form>
    </React.Fragment>
  );
};

// export default KroonTand;
function mapStateToProps(state: any) {
  console.log("init with formData:");
  console.log(state.formData);
  return { formData: state.formData };
}

export default connect(mapStateToProps)(Tandvlees);
