import { useOutletContext } from 'react-router-dom';
import { praktijkinfo_load } from '../types/praktijkinfo';
import Persoonsgegevens from './Persoonsgegevens';
import WieTandarts from './WieTandarts';


export default function RootIndex() {
    const praktijkInfo: praktijkinfo_load = useOutletContext();
    if (praktijkInfo === null) {
        return <></>
    }
    var is_spoed_praktijk = praktijkInfo['is_spoed_praktijk'];
    if (is_spoed_praktijk) {
        return <WieTandarts />
    } else {
        return <Persoonsgegevens />
    }
}