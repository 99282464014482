import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { klacht_data, persoonsgegevens } from "../types/klacht_data";
import Submit from "./Submit";

function submitFunc(
  data: klacht_data,
  setLoading: (a: boolean) => void,
  navigate: NavigateFunction,
  location: Location
) {
  Submit(data).then((response: Response | boolean) => {
    setLoading(false);
    if (response instanceof Response && response.status === 200) {
      if (data["spoed"] === "ja") {
        navigate("/spoed" + location.search);
      } else {
        navigate("/thankyou" + location.search);
      }
    } else {
      navigate("/fout" + location.search);
    }
  });
}

export function OnLastSubmitGegevens(
  setLoading: (a: boolean) => void,
  klacht_data: klacht_data
) {
  const navigate = useNavigate();
  const location = useLocation();
  return async (data: persoonsgegevens) => {
    setLoading(true);
    klacht_data["persoonsgegevens"] = data;
    submitFunc(klacht_data, setLoading, navigate, location);
  };
}
//

export function OnLastSubmitKlacht(
  setLoading: (a: boolean) => void,
  foto_blob_url: string | null | undefined,
  klacht: string
) {
  console.log(foto_blob_url);
  const navigate = useNavigate();
  const location = useLocation();
  return async (data: klacht_data) => {
    setLoading(true);
    if (klacht) {
      data["klacht"] = klacht;
    }
    if (foto_blob_url) {
      data["foto_blob_url"] = foto_blob_url;
    }
    submitFunc(data, setLoading, navigate, location);
  };
}
