import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  formLabelClasses,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import TriadentStepper from "../components/TriadentStepper";
import { ErrorMessage } from "@hookform/error-message";
import { OnLastSubmitKlacht } from "../components/OnLastSubmit";
import { useOutletContext } from "react-router-dom";
import OnNextSubmit from "../components/OnNextSubmit";
import Pagination from "../components/Pagination";
import { klachtCompProps } from "../types/redux";
import { extra_klacht_data } from "../types/klacht_data";
import { praktijkinfo } from "../types/praktijkinfo";
import { uploadPhoto_utils } from "../utils/uploadPhoto_utils";
import { photoBox } from "utils/photoBox";

let loading = false;
const setLoading = (bool: boolean) => {
  loading = bool;
};

interface AndersProps extends klachtCompProps {}

const Anders: React.FC<AndersProps> = (props) => {
  const [foto, setFoto] = React.useState(props.formData.foto);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: props.formData,
  });
  const { t, i18n } = useTranslation();

  const uploadPhoto = uploadPhoto_utils(setFoto);

  const onLastSubmit = OnLastSubmitKlacht(setLoading, foto, "Anders");

  const extra_data: extra_klacht_data = { klacht: "Anders" };
  if (foto) {
    extra_data["foto_blob_url"] = foto;
  }

  const onNextSubmit = OnNextSubmit(
    props.dispatch,
    extra_data,
    "/persoonsgegevens"
  );

  const praktijkInfo: praktijkinfo = useOutletContext();
  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];
  const onSubmit = onLastSubmit;

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="klacht" />
      <ErrorMessage
        errors={errors}
        name={Object.keys(errors)[0]}
        render={({ message }) => (
          <p className={"error-message"}>
            {Object.keys(errors).map((e) => t(e)).length == 1
              ? `${t("Het veld")} ${
                  Object.keys(errors).map((e) => t(e))[0]
                } ${t("is verplicht")}`
              : `${t("De velden")} ${Object.keys(errors)
                  .map((e) => t(e))
                  .join(", ")
                  .replaceAll("_", " ")
                  .replace(/,(?=[^,]*$)/, " en")} ${t("zijn verplicht")}`}
          </p>
        )}
      />
      <Typography>{t("Vul de volgende gegevens in")}:</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={-1} spacing={3}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              component="fieldset"
              error={!!errors.klacht_uitleg}
            >
              <FormLabel sx={{ mb: 1 }} component="legend">
                {t("Kunt u de klacht zo goed mogelijk omschrijven?")}
              </FormLabel>

              <TextField
                placeholder={t("Licht toe") + "..."}
                InputProps={register("klacht_uitleg", { required: true })}
                multiline
                minRows={2}
                maxRows={5}
              />
            </FormControl>
          </Grid>

          {photoBox(t, foto, uploadPhoto)}
        </Grid>

        <Pagination loading={loading} submit={true} />
      </form>
    </React.Fragment>
  );
};

// export default KroonTand;
function mapStateToProps(state: any) {
  return { formData: state.formData };
}

export default connect(mapStateToProps)(Anders);
