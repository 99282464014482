import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import praktijkInfo from './praktijkInfo';
import { useTranslation } from 'react-i18next';
import flag_nl from '../images/flags/nl.svg';
import flag_eng from '../images/flags/gb.svg';
import flag_ua from '../images/flags/ua.svg';
import flag_ar from '../images/flags/ar.svg';
import flag_de from '../images/flags/de.svg';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

let praktijk_info = praktijkInfo();

var flag_src = {
    'ar': flag_ar,
    'de': flag_de,
    'en': flag_eng,
    'nl': flag_nl,
    'ua': flag_ua
}

var language_full = {
    'en': 'English',
    'ua': 'український',
    'ar': 'عربي ',
    'nl': 'Nederlands',
    'de': 'Deutsch'
}

const useStyles = makeStyles((theme) => ({
    flag: {
        height: 20,
        width: 'auto',
        float: 'right',
        cursor: 'pointer',
        margin: 2,
    },
}))



export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}


function SimpleDialog(props: SimpleDialogProps) {
    const { t } = useTranslation();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value: string) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open} className={'menu-dialog'}>
            <DialogTitle>{t('Taal')}</DialogTitle>
            <List sx={{ pt: 2, pl: 2, pr: 2 }}>
                {Object.keys(flag_src).map((language) => (
                    <ListItem button onClick={() => handleListItemClick(language)} key={language}>
                        <ListItemAvatar>
                            <img src={flag_src[language as keyof typeof flag_src]}></img>
                        </ListItemAvatar>
                        <ListItemText sx={{ ml: 1 }} primary={language.toUpperCase()} secondary={language_full[language as keyof typeof language_full]} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}



export default function LanguageSwitcher() {
    const classes = useStyles();


    const { t, i18n } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(i18n.language);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (language: string) => {
        setOpen(false);
        setSelectedValue(language);
        console.log(language);
        i18n.changeLanguage(language);
    };


    return (
        <React.Fragment>
            <Box mt={praktijk_info['flag-menu-margin-top']} sx={{ display: 'flex', '&:hover': { cursor: 'pointer' } }} onClick={handleClickOpen}>

                <img src={flag_src[i18n.language as keyof typeof flag_src]} className={classes.flag}></img>
                <Typography sx={{ ml: '1px' }}>{i18n.language.toUpperCase()}</Typography>
                <KeyboardArrowDownIcon />



            </Box>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
        </React.Fragment>

    )
}