let initialState = {
  "persoonsgegevens": {
    "geboortedatum": null,
  },
  'stress': null,
  'welke_prothese': null,
  'prothese_los': null,
  'drukplek_lange_rand': null,
  'scheurtje': null,
  'prothese_gebroken': null,
  'prikkels': null,
  'implantaat_probleem': null,
  'pijn': null,
  'pijnstillers': null,
  'helpen_pijnstillers': null,
  'bloeden': null,
  'spalk_los': null,
  'welke_spalk': null,
  'beugel_spalk': null,
  'tandvlees_behandelingen': null,
  'tandvleesproblematiek_familie': null,
  'bekend_klemmen_knarsen': null,
  'vreemd_plekje': null,
  'pijn_ontstaan': null,
  'zwelling': null, "spoed": null,
  'pijn_omschrijving': null,
  'tijd_eenheid': 'dagen',
  'tijd_geleden': 0, 'foto': null, 'tand_locatie': null, 'scherp': null, 'ongeluk': null
}


if (new URLSearchParams(window.location.search).has('voorbeeld')) {
  initialState = {
    ...initialState,
    ...{
      "persoonsgegevens": {
        'voornaam': 'Jan',
        'achternaam': 'Cornelis',
        "email": "test@gmail.com",
        "geboortedatum": new Date("1973-01-08T15:11:03.088Z"),
        "telefoonnummer": "0612345678",
        "ingeschreven": "ja",
        "spoed": "nee"
      }
    }
  }
}




export default function (state = initialState, action) {
  switch (action.type) {
    case "formData": {
      state = { ...state, ...action.payload.formData };
      return state;
    }
    default:
      return state;
  }
}
