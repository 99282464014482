import { useLocation, Link } from 'react-router-dom';


interface props {
  to: string;
  children: React.ReactNode;
  [x: string]: any;
}

export default function QueryLink({ to, ...props }: props) {
  let location = useLocation();
  return (
    <Link to={to + location.search} {...props} />
  );
}