import React, { useEffect } from 'react';
import { Box, Grid, Paper, Button, TextareaAutosize, useMediaQuery } from '@mui/material';
import tand from './../images/tand.png';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import TriadentStepper from '../components/TriadentStepper';
import QueryLink from '../components/QueryLink';
import { useNavigate, useLocation } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom'; import { connect } from 'react-redux';
import { praktijkinfo } from '../types/praktijkinfo';


interface OnderdelenProps {

}
interface LogoButtonProps {
    categorie: string;
    text: string;
    src: string;
}

const Onderdelen: React.FC<OnderdelenProps> = (props) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { t, i18n } = useTranslation();
    const praktijkInfo: praktijkinfo = useOutletContext();
    if (!praktijkInfo) {
        return <></>
    }
    var is_spoed_praktijk = praktijkInfo['is_spoed_praktijk'];


    const LogoButton: React.FC<LogoButtonProps> = (props) => {
        return <Grid item id="5" >
            <QueryLink to={`/${props.categorie}`} style={{ display: 'contents' }}>
                <Button sx={{ padding: '0' }} >
                    <Box className={"logo_button"} pt={1} sx={{ transition: '0.3s', '&:hover': { borderColor: 'rgba(30, 74, 113, 0.3)' }, width: { xs: '140px', md: '160px' }, border: '1px solid #e8e8e8', borderRadius: '5%', display: 'grid', justifyItems: 'center' }}>
                        <img src={require("./../images/iconen/" + props.src + ".png")} height={isSmall ? '50px' : '60px'} />
                        <Typography sx={{ 'textTransform': 'capitalize', "margin": "8px 0 4px" }}>{props.text}</Typography>
                    </Box>
                </Button>
            </QueryLink>

        </Grid>
    }


    return (
        <React.Fragment>
            <TriadentStepper onderdeel="klacht_onderdeel" />

            <Typography component="h3" align="center">
                {t("Waar heeft uw klacht betrekking op?")}
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
                <Grid mt={0} container spacing={isSmall ? 2 : 3} justifyContent="center" direction="row">
                    <LogoButton src="Tand" text={t("Tand/kies")} categorie={'tand'} />
                    <LogoButton src="Verstandskies" text={t("Verstandskies")} categorie={'verstandskies'} />
                    <LogoButton src="Tandvlees" text={t("Tandvlees")} categorie={'tandvlees'} />
                    <LogoButton src="Implantaat" text={t("Implantaat")} categorie={'implantaat'} />
                    <LogoButton src="Prothese" text={t("Prothese")} categorie={'prothese'} />
                    <LogoButton src="Beugel-spalk" text={t("Beugel/spalk")} categorie={'beugel_spalk'} />
                    <LogoButton src="Losgekomenbrug-kroon" text={t("Kroon/brug")} categorie={'losgekomen_brug_kroon'} />
                    <LogoButton src="Anders" text={t("Anders")} categorie={'anders'} />

                </Grid>
            </Box>


            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', }} >
                {!is_spoed_praktijk &&
                    <QueryLink to="/">
                        <Button variant="outlined" sx={{
                            marginTop: 3,
                        }}>{t("Terug")}</Button>
                    </QueryLink>
                }
            </Box>
        </React.Fragment>
    )
}

export default connect()(Onderdelen);