import { useOutletContext } from "react-router-dom";
import NextButton from "./NextButton";
import BackSubmit from "./BackSubmit";
import React from "react";
import QueryLink from "./QueryLink";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import back_routes from "../utils/back_route";
import { connect } from "react-redux";
import { klacht_data } from "../types/klacht_data";
import { praktijkinfo_load } from "../types/praktijkinfo";

interface props {
  loading: boolean;
  formData: klacht_data;
  submit?: boolean;
}

function Pagination({ loading, formData, submit = false }: props) {
  const { t, i18n } = useTranslation();
  const praktijkInfo: praktijkinfo_load = useOutletContext();
  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];
  if (is_spoed_praktijk) {
    if (submit) {
      var back_link = back_routes(formData.klacht);
      return <BackSubmit loading={loading} back_link={`/${back_link}`} />;
    } else {
      back_link = "onderdelen";
      return (
        <Box sx={{ mt: 5, display: "flex", justifyContent: "flex-end" }}>
          <QueryLink to={`/${back_link}`}>
            <Button
              variant="outlined"
              sx={{
                marginTop: 5,
                marginLeft: 1,
              }}
            >
              {t("Terug")}
            </Button>
          </QueryLink>
          {!submit && <NextButton />}
        </Box>
      );
    }
  } else {
    return <BackSubmit loading={loading} back_link="/onderdelen" />;
  }
}

interface state {
  formData: klacht_data;
}

// export default KroonTand;
function mapStateToProps(state: state) {
  return { formData: state.formData };
}

export default connect(mapStateToProps)(Pagination);
