import { Box, Button, FormLabel, Grid } from '@mui/material';
import React from 'react';

export function photoBox(t: any, foto: string | null | undefined, uploadPhoto: (e: React.FormEvent<HTMLInputElement>) => void) {
    return <Grid item xs={12}>
        <FormLabel component="legend">{t("Voeg een foto toe (optioneel)")}:</FormLabel>

        <Box mt={foto === null ? 1 : 2}>
            {foto &&
                <img src={foto} style={{ "maxWidth": '80%', 'maxHeight': '600px' }} />}
        </Box>
        <Button variant="contained" component="label">
            {foto == null ? t("upload foto") : t("upload andere foto")}
            <input accept="image/*" type="file" hidden onChange={uploadPhoto} /></Button>

    </Grid>;
};
