export default function back_routes(klacht: string) {
    var route;
    switch (klacht) {
        case 'Afgebroken tand':
            route = "afgebroken_tand"
            break;
        case 'Kroon of brug losgekomen':
            route = "losgekomen_brug_kroon"
            break;
        case 'Pijn aan tand':
            route = "pijn_tand"
            break;
        case 'Verstandskies':
            route = "verstandskies"
            break;
        case 'Tandvlees':
            route = "tandvlees"
            break;
        case 'Implantaat':
            route = "implantaat"
            break;
        case 'Prothese':
            route = "prothese"
            break;
        case 'Beugel of spalk':
            route = "beugel_spalk"
            break;
        case 'Hoge vulling':
            route = "hoge_vulling"
            break;
        case 'Losse Tand':
            route = "losse_tand"
            break;
        case 'Anders':
            route = "anders"
            break;
        default:
            route = "onderdelen"
            break;
    }
    console.log(route);
    return route;
}