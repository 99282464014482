import { useSearchParams } from 'react-router-dom';
import { bool_klacht_data, klacht_data } from '../types/klacht_data';
import praktijkInfo from './praktijkInfo';
import { subdomain } from './praktijkInfo'

const commaAnd = (str: string): string => {
    str = str.replace(/,(?=[^,]*$)/, ' en')
    return str;
}


export default async function Submit(input_data: klacht_data): Promise<Response | boolean> {

    const transformData = (input_data: klacht_data): klacht_data => {

        input_data = { ...input_data['persoonsgegevens'], ...input_data };
        delete input_data.persoonsgegevens;

        // Convert prikkel checkboxes to signle item
        let prikkels = ["kou_prikkel", "warmte_prikkel", "druk_prikkel", "zoet_prikkel", "zuur_prikkel", "andere_prikkel"];
        prikkels = prikkels.filter(e => input_data[e as keyof klacht_data]);
        var idx = prikkels.indexOf('andere_prikkel');
        if (idx !== -1) {
            prikkels[idx] = input_data['andere_prikkel'] || '';
        }
        if (typeof (input_data['prikkels']) === "string" && prikkels.length !== 0) {
            input_data['prikkels'] = prikkels.join(', ').replaceAll('_prikkel', '');
            input_data['prikkels'] = commaAnd(input_data['prikkels']);
        } else {
            delete input_data['prikkels'];
        }


        // convert locatie checkboxes to single item

        let locaties = ['rechtsboven_locatie', 'linksboven_locatie', 'linksonder_locatie', 'rechtsonder_locatie', 'middenonder_locatie', 'middenboven_locatie', 'op onbekende plek_locatie'];
        locaties = locaties.filter((e) => input_data[e as keyof klacht_data]);

        if (locaties.length !== 0) {
            input_data['tand_locatie'] = locaties.join(', ').replaceAll('_locatie', '');
            input_data['tand_locatie'] = commaAnd(input_data['tand_locatie']);
        }

        // convert pijnstiller checkboxes to signle item
        let pijnstillers = ["paracetamol_pijnstiller", "ibuprofen_pijnstiller", "andere_pijnstiller"];
        pijnstillers = pijnstillers.filter((e) => input_data[e as keyof klacht_data]);
        idx = pijnstillers.indexOf('andere_pijnstiller');
        if (idx !== -1) {
            pijnstillers[idx] = input_data['andere_pijnstiller'] || "";
        }


        if (pijnstillers.length !== 0) {
            input_data['pijnstillers'] = pijnstillers.join(', ').replaceAll('_pijnstiller', '');
            input_data['pijnstillers'] = commaAnd(input_data['pijnstillers']);
        } else {
            delete input_data['pijnstillers'];
        }

        for (let k in input_data) {
            if (k.includes('_pijnstiller') || k.includes('_prikkel')) {
                if (k != 'helpen_pijnstillers') {
                    delete input_data[k as keyof klacht_data];
                }
            }
        }



        if (input_data['tijd_geleden'] == 1) {
            switch (input_data['tijd_eenheid']) {
                case 'dagen':
                    input_data['tijd_eenheid'] = 'dag';
                    break;
                case 'weken':
                    input_data['tijd_eenheid'] = 'week';
                    break;
                case 'maanden':
                    input_data['tijd_eenheid'] = 'maand';
                    break;
            }
        }


        if (input_data['implantaat_probleem'] == 'anders') {
            input_data['implantaat_probleem'] = input_data['ander_probleem_implantaat'] || '';
            delete input_data['ander_probleem_implantaat']
        }

        // Convert ja, nee values to true, false
        for (let [k, value] of Object.entries(input_data)) {
            if (value === "ja") {
                input_data[k as keyof bool_klacht_data] = true;
            } else if (value === 'nee') {
                input_data[k as keyof bool_klacht_data] = false;
            }
        }

        input_data['vanuit_dashboard'] = new URLSearchParams(window.location.search).has('vanuit_dashboard') ? true : false
        return input_data;
    }

    function submitToApi(data: klacht_data): Promise<Response | boolean> {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        let address;
        if (window.location.hostname === 'localhost') {
            address = 'http://127.0.0.1:8000/';
        } else {
            address = 'https://api.triadent.nl/';
        }
        const address_tot = `https://triadent.nl/api/praktijken/1/aanvragen`;
        return fetch(`${address}api/praktijken/aanvragen/?subdomain=${subdomain}`, requestOptions)
            .then(response => response).catch(error => {
                return false;
            })

    }


    let data = transformData(input_data);


    if (data['foto_blob_url'] && data['foto_blob_url'] !== null) {

        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onloadend = async () => {
                if (typeof reader.result === "string")
                    data['foto'] = reader.result;

                resolve(submitToApi(data));
            };
            reader.onerror = () => {
                reject('oops, something went wrong with the file reader.')
            }
            fetch(data['foto_blob_url']).then(r => r.blob().then(blob => { reader.readAsDataURL(blob) }));
        })
    } else {
        return submitToApi(data);
    }
}