import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TriadentStepper from "../components/TriadentStepper";
import { useOutletContext } from "react-router-dom";
import { praktijkinfo } from "../types/praktijkinfo";
import { connect } from "react-redux";
import { klachtCompProps } from "types/redux";

// Alleen voor spoed
// Bij een losgkomen tand/brug melding kunnen we de kroong/brug alleen met een tijdelijk cement vastzetten. U zult alsnog naar de eigen tandarts moeten gaan voor een definitieve oplossing.
// Als implantaat er volledig uit is, doorverwijzen naar implantoloog
// Bij tand die er volledig uit is gevallen-> Instructie wat te doen: 1) niet de wortel aanraken. 2) Tand afspoelen met lauw warm water/speeksel en zo spoedig mogelijk terugplaatsen in de tandkas. 3) Lukt dat niet? Dan onder de tong bewaren.
// Bij een Trauma -> Laat de patient langskomen en overleg met de tandarts.
// Bij een nabloeding  Als de patient geen bloedverdunners gebruikt -> de instructie geven om 45 minuten op een gaasje of tampon te bijten en niet te praten, spoelen of verwijderen. Als het dan niet gestopt is, dan verzoeken om terug te bellen.
// Als de patient bloedverdunner gebruikt -> Laat de patient langskomen en overleg met de tandarts.
// Bij hoofdhals gebied zwelling -> Laat de patient meteen langskomen en overleg met de tandarts.
//

// New fields:
// gaas_gebeten
// hoofdhals_zwelling
// bloed_verdunners
// vulling_tand
// wanneer_extractie
// kiezen_op_elkaar
// stip_streep
// beweegt_2mm
// uit_afgebroken
// trauma_nabloeding
// is_kroon_heel
// is_kroon_niet_weg
// losgekomen_brug
// wang

interface ThankyouProps extends klachtCompProps {}

const Thankyou: React.FC<ThankyouProps> = ({ formData }) => {
  const { t } = useTranslation();
  const praktijkInfo: praktijkinfo = useOutletContext();
  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];

  var vanuit_dashboard = new URLSearchParams(window.location.search).has(
    "vanuit_dashboard"
  );

  var tekst1;
  if (formData.implantaat_probleem === "is_er_volledig_uit") {
    tekst1 =
      "Omdat het implantaat van de patient is uitgevallen, kunnen wij de patiënt hier niet passend helpen. Verwijs diegene daarom door naar zijn/haar implantoloog.";
  } else if (formData.klacht === "Verstandkies") {
    tekst1 =
      "In het geval van pijn, adviseer waterstofperoxide 1:1 met water te verdunenn. En op een gaastje te bijten voor 30 minuten.";
  } else {
    tekst1 =
      "Gebruikt u medicatie? Zo ja, graag een medicatie overzicht meenemen naar de afspraak.";
  }

  var public_text =
    t("Wij zullen binnen één werkdag telefonisch contact met u opnemen") +
    ". " +
    t(
      "Mocht u zich grote zorgen maken om uw gezondheid, dan verzoeken wij u zo snel mogelijk zelf telefonisch contact op te nemen met de praktijk"
    ) +
    ".";

  var extraTekst = "";
  if (formData.losgekomen_brug === "ja") {
    extraTekst =
      "We kunnen de kroon/brug alleen met een tijdelijk cement vastzetten. U zult alsnog naar de eigen tandarts moeten gaan voor een definitieve oplossing.";
  } else if (formData.implantaat_probleem === "is_er_volledig_uit") {
    extraTekst = "We verwijzen u graag door naar de implantoloog";
  } else if (formData.zwelling === "ja") {
    extraTekst = "Laat de patient langskomen en overleg met de tandarts.";
  }

  if (formData.in_zicht === "nee") {
    extraTekst +=
      "Direct afspraak maken, maar geef aan dat we het met tijdelijk vastgezet wordt.";
  }

  if (formData.is_kroon_niet_weg === "nee") {
    extraTekst = "Geef advies om contact op te nemen met eigen tandarts.";
  }

  if (formData.implantaat_probleem === "afdekschroef_losgekomen") {
    extraTekst +=
      "Geef aan dat het we het afdekschroefje alleen kunnen terugplaatsen als diegene het nog heeft.";
  }

  if (formData.implantaat_probleem === "kroon_losgekomen") {
    extraTekst +=
      "Geef aan dat we de kroon alleen kunnen terugplaatsen als diegene het nog heeft.";
  }

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="bevestiging" />
      <Typography variant="h5" gutterBottom>
        {t("Wij hebben uw aanvraag voor een spoedafspraak ontvangen")}.
      </Typography>
      <Typography variant="subtitle1">
        {!vanuit_dashboard ? public_text : t("Deze staat nu in het dashboard.")}
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        {is_spoed_praktijk && tekst1}
      </Typography>
      {extraTekst &&
        is_spoed_praktijk &&
        (formData.klacht !== "Implantaat" ||
          formData.implantaat_probleem !== "is_er_volledig_uit") && (
          <Typography variant="subtitle1">{extraTekst}</Typography>
        )}
    </React.Fragment>
  );
};

function mapStateToProps(state: any) {
  return { formData: state.formData };
}

export default connect(mapStateToProps)(Thankyou);
