import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import makeStyles from "@mui/styles/makeStyles";
import flag_eng from "../images/flag_eng.png";
import tand from "../images/tand.png";
import Typography from "@mui/material/Typography";
import image404 from "../images/image-404.jpg";
import Link from "@mui/material/Link";
import flag_nl from "../images/flag_nl.png";
import {
  Box,
  Button,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { connect } from "react-redux";
import enLocale from "date-fns/locale/en-US";
import nlLocale from "date-fns/locale/nl";
import { useTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import praktijkInfo from "../components/praktijkInfo";
import LanguageSwitcher from "../components/LanguageSwitcher";
import { subdomain } from "../components/praktijkInfo";
import { Theme } from "@mui/material/styles";
import { persoonsgegevens } from "types/klacht_data";

let praktijk_info = praktijkInfo();
const isIframe = new URLSearchParams(window.location.search).has("iframe");
const no_center = new URLSearchParams(window.location.search).has("no_center");

// it could be your App.tsx file or theme file that is included in your tsconfig.json

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },

  logo: {
    height: praktijk_info.logo_height,
    maxWidth: 200,
  },
  to_right: {
    marginLeft: "auto",
    marginRight: "20px",
  },

  layout: {
    marginTop: theme.spacing(3),
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      ...(!no_center && { width: 600 }),
      ...(!no_center && { marginLeft: "auto" }),
      ...(!no_center && { marginRight: "auto" }),
    },
  },
  margin_top: {
    marginTop: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    // [theme.breakpoints.up(600)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3),
    // },
  },
}));

let address: string;
if (window.location.hostname === "localhost") {
  address = "http://127.0.0.1:8000";
} else {
  address = "https://api.triadent.nl";
}

interface TotalAppProps {
  persoonsgegevens: persoonsgegevens;
}
console.log(subdomain);

const TotalApp: React.FC<TotalAppProps> = ({ persoonsgegevens }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [praktijkInfo, setPraktijkInfo] = useState(null);

  useEffect(() => {
    if (
      location.pathname !== "/" &&
      Object.keys(persoonsgegevens).length === 1
    ) {
      navigate("/");
    }

    // if (subdomain !== "localhost") {
    fetch(`${address}/api/praktijk/info?subdomain=${subdomain}`)
      .then((response) => response.json())
      .then((praktijk_info) => {
        praktijk_info["is_spoed_praktijk"] =
          praktijk_info["keten_naam"] === "Tandarts Spoedpraktijk" ||
          praktijk_info["keten_naam"] === "spoed" ||
          praktijk_info["praktijk_naam"] === "Spoed Utrecht";
        setPraktijkInfo(praktijk_info);
      });
    // }
  }, []);

  if (praktijkInfo !== null && !praktijkInfo["is_praktijk"]) {
    const extra_err_message = praktijkInfo["is_keten"] ? (
      <p>
        Deze pagina kunnen we niet vinden. Klik{" "}
        <a href="/dashboard" rel="noreferrer">
          hier{" "}
        </a>{" "}
        om naar het dashboard te gaan
      </p>
    ) : (
      <p>Deze pagina kunnen we niet vinden. Probeer het opnieuw</p>
    );
    return (
      <Box id="error-page">
        <h1>Onbekende pagina</h1>
        {extra_err_message}
        <img src={image404} alt="Logo" />
      </Box>
    );
  }

  const localeMap = {
    en: enLocale,
    nl: nlLocale,
  };

  function Copyright() {
    const classes = useStyles();

    return (
      <Typography
        className={classes.margin_top}
        variant="body2"
        color="textSecondary"
        align="center"
      >
        {"Copyright © "}
        {/* <Link href="https://triadent.nl/"> */}
        Triadent
        {/* </Link> */} {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  return (
    <div className="App">
      <LocalizationProvider
        locale={localeMap[i18n.language as keyof typeof localeMap]}
        dateAdapter={AdapterDateFns}
      >
        <CssBaseline />

        {!new URLSearchParams(window.location.search).has("vanuit_dashboard") &&
          !isIframe && (
            <header className="App-header">
              <AppBar
                position="absolute"
                color="default"
                className={classes.appBar}
              >
                <Toolbar>
                  <a href={"/"}>
                    <img
                      src={praktijk_info.logo}
                      alt="logo"
                      className={classes.logo}
                    />
                  </a>
                  <div className={classes.to_right}>
                    <LanguageSwitcher />
                  </div>
                </Toolbar>
              </AppBar>
            </header>
          )}
        {(new URLSearchParams(window.location.search).has("vanuit_dashboard") ||
          isIframe) && (
          <Box
            position={"absolute"}
            bottom={isIframe ? 0 : 20}
            left={isIframe ? 20 : 56}
          >
            <LanguageSwitcher />
          </Box>
        )}
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography mt={1} component="h1" variant="h5" align="center">
              {t("Maak een spoedafspraak met ")}{" "}
              {praktijkInfo && praktijkInfo["praktijk_naam"]}
            </Typography>
            <Outlet context={praktijkInfo} />
          </Paper>
          {!new URLSearchParams(window.location.search).has(
            "vanuit_dashboard"
          ) && <Copyright />}
        </main>
      </LocalizationProvider>
    </div>
  );
};

function mapStateToProps(state: any) {
  return { persoonsgegevens: state.formData["persoonsgegevens"] };
}

export default connect(mapStateToProps)(TotalApp);
