import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldError, FieldErrors } from 'react-hook-form';
import { bool_klacht_data, klacht_data } from '../types/klacht_data';


type errors = {
    [key in keyof bool_klacht_data]?: FieldError | undefined;
};

export function radiogroep_util(errors: errors, control: Control<klacht_data, object>) {
    return (radio_values: string[], radio_value_labels: string[], naam: keyof klacht_data, label: string, sx = {}) => {
        const Cap_space = (str: string) => str.charAt(0).toUpperCase() + str.replace(/_/g, ' ').slice(1);
        return (
            <FormControl component="fieldset" error={!!errors[naam as keyof typeof errors]}>
                <FormLabel sx={sx} component="legend">{label}</FormLabel>
                <Controller
                    rules={{ required: true }}
                    control={control}
                    name={naam}
                    render={({ field }) => {
                        return (
                            <RadioGroup {...field} row>
                                {radio_values.map((radio_value, idx) => {
                                    return (
                                        <FormControlLabel
                                            key={radio_value}
                                            value={radio_value}
                                            control={<Radio />}
                                            label={Cap_space(radio_value_labels[idx])} />
                                    );
                                })}
                            </RadioGroup>
                        );
                    }} />
            </FormControl>
        );
    };
}
