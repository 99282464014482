import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TriadentStepper from "../components/TriadentStepper";
import { useOutletContext } from "react-router-dom";
import { praktijkinfo } from "types/praktijkinfo";
import { connect } from "react-redux";
import { klachtCompProps } from "types/redux";

interface SpoedProps extends klachtCompProps {}

const Spoed: React.FC<SpoedProps> = ({ formData }) => {
  const { t } = useTranslation();

  const praktijkInfo: praktijkinfo = useOutletContext();
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];
  var vanuit_dashboard = new URLSearchParams(window.location.search).has(
    "vanuit_dashboard"
  );

  var telefoon = "";
  if (praktijkInfo && praktijkInfo.telefoon && praktijkInfo.telefoon !== "") {
    telefoon = t("Dit kan op telefoonnummer: ") + praktijkInfo.telefoon + ".";
  }

  var stdTekst = `\nBij deze patiënt is het belangrijk dat hij/zij zo snel mogelijk een gezien wordt door een tandarts. \nPlan de afspraak zo snel mogelijk in en overleg met de tandarts.`;

  var extraTekst = null;

  if (formData.klacht === "trauma") {
    if (formData.uit_afgebroken === "ligt_er_uit") {
      extraTekst = `Geef de volgende instructies mee aan de patient: \n1) raak de wortel niet aan \n2) tand afspoelen met lauw warm water of speeksel en zo snel mogelijk terugplaatsen in de tandkas. \n3) lukt dat niet? dan onder de tong bewaren.`;
    }
    extraTekst += stdTekst;
  } else if (
    formData.klacht === "nabloeding" &&
    formData.bloed_verdunners === "nee"
  ) {
    extraTekst =
      "De instructie geven om 45 minuten op een gaasje of tampon te bijten en niet te praten, spoelen of verwijderen. Als het dan niet gestopt is, dan verzoeken om terug te bellen.";
  } else if (
    formData.klacht === "nabloeding" &&
    formData.bloed_verdunners === "ja"
  ) {
    extraTekst =
      "De instructie geven om 45 minuten op een gaasje of tampon te bijten en niet te praten, spoelen of verwijderen.";
    extraTekst += stdTekst;
  }

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="bevestiging" />
      <Typography variant="h5" gutterBottom>
        {vanuit_dashboard && "Let op!"}

        {!vanuit_dashboard && t("Neem telefonisch contact op met de praktijk")}
      </Typography>
      <Typography variant="subtitle1">
        {vanuit_dashboard &&
          !extraTekst &&
          "Bij deze patiënt is het belangrijk dat hij/zij zo snel mogelijk een gezien wordt door een tandarts. Zorg ervoor dat de patiënt vandaag gezien wordt. Omschrijf de klacht zelf in de kaart."}

        {!vanuit_dashboard &&
          t(
            "Voor uw type klacht is het van belang om zsm contact op te nemen met de praktijk"
          ) +
            ". " +
            telefoon}

        {extraTekst && is_spoed_praktijk && (
          <Typography
            sx={{ mt: 2, whiteSpace: "pre-line" }}
            variant="subtitle1"
          >
            {extraTekst}
          </Typography>
        )}
      </Typography>
    </React.Fragment>
  );
};

function mapStateToProps(state: any) {
  return { formData: state.formData };
}

export default connect(mapStateToProps)(Spoed);
