import { useTranslation } from 'react-i18next';
import { Button, Box } from '@mui/material';

export default function NextButton() {
    const { t, i18n } = useTranslation();
    return (
        <Box sx={{ mt: 5, display: 'flex', justifyContent: 'flex-end', }} >

            <Button
                sx={{
                    marginTop: 0,
                    marginLeft: 1
                }}
                type="submit"
                variant="contained"
                color="primary">
                {t("Volgende")}
            </Button>
        </Box>
    )
}