import { FormControl, FormLabel, Grid, TextField } from "@mui/material";
import React from "react";
import { UseFormRegister } from "react-hook-form";
import { klacht_data } from "../types/klacht_data";

export function extra_notities(
  t: any,
  register: UseFormRegister<klacht_data>,
  errors: any
): React.ReactNode {
  const CustTextField = ({ name, label }: { name: string; label: string }) => (
    <Grid item xs={12}>
      <FormControl fullWidth component="fieldset" error={!!errors[name]}>
        <FormLabel sx={{ mb: 1 }} component="legend">
          {label}
        </FormLabel>

        <TextField
          placeholder={"Licht toe..."}
          InputProps={register(name, { required: false })}
          multiline
          minRows={1}
          maxRows={1}
        />
      </FormControl>
    </Grid>
  );
  return (
    <>
      <CustTextField
        name="waarom_niet_eigen_tandarts"
        label="Wat is de reden dat u niet bij uw eigen tandarts geholpen kunt worden?"
      />
      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset">
          <FormLabel sx={{ mb: 1 }} component="legend">
            {t("Extra notities (optioneel):")}
          </FormLabel>
          <TextField
            placeholder={t("Licht toe") + "..."}
            InputProps={register("extra_notitie", { required: false })}
            multiline
            minRows={2}
            maxRows={5}
          />
        </FormControl>
      </Grid>
    </>
  );
}
