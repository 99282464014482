import triadent_logo from "../images/logo_triadent.png";
import huesmolen_logo from "../images/logo_huesmolen.png";
import mondhuys_logo from "../images/mondhuys-kleiner.png";
import puur_logo from "../images/puur.svg";
import dental365_logo from "../images/dental365_logo.png";
import { praktijkinfo } from "../types/praktijkinfo";

var local_host_subdomain =
  new URLSearchParams(window.location.search).get("subdomain") ?? "spoed";

export const subdomain = window.location.host.split(".")[2]
  ? window.location.host.split(".")[0]
  : local_host_subdomain;

export default function praktijkInfo() {
  let praktijkinfo: Partial<praktijkinfo> = {};
  praktijkinfo["subdomain"] = window.location.host.split(".")[1]
    ? window.location.host.split(".")[0]
    : false;

  function setPuurLogo(praktijkinfo: Partial<praktijkinfo>) {
    praktijkinfo["logo"] = puur_logo;
    praktijkinfo["logo_height"] = "35px";
    praktijkinfo["flag-menu-margin-top"] = "8px";
    return praktijkinfo;
  }
  if (window.location.host.split(":")[0] == "localhost") {
    praktijkinfo["logo"] = triadent_logo;
    praktijkinfo["logo_height"] = "28px";
    praktijkinfo["flag-menu-margin-top"] = "2px";
    return praktijkinfo;
  }

  let mondzorg_subdomains = [
    "dokkumpuur",
    "scheemdapuur",
    "stadskanaalpuur",
    "delfzijlpuur",
    "veendampuur",
    "appingedampuur",
    "hoogezandpuur",
    "rodenpuur",
    "rollemastatepuur",
    "bellingwoldepuur",
    "hoogeveenpuur",
    "franekerpuur",
    "paterswoldepuur",
    "emmenpuur",
    "spoorbaanpuur",
  ];
  if (
    praktijkinfo["subdomain"] &&
    mondzorg_subdomains.includes(praktijkinfo["subdomain"])
  ) {
    praktijkinfo = setPuurLogo(praktijkinfo);
  } else {
    switch (praktijkinfo["subdomain"]) {
      case "tpdehuesmolen":
        praktijkinfo["logo"] = huesmolen_logo;
        praktijkinfo["logo_height"] = "35px";
        praktijkinfo["flag-menu-margin-top"] = "8px";
        break;
      case "tpl":
        praktijkinfo["logo"] = triadent_logo;
        praktijkinfo["logo_height"] = "28px";
        praktijkinfo["flag-menu-margin-top"] = "2px";
        break;
      case "havenga":
        praktijkinfo["logo"] = triadent_logo;
        praktijkinfo["logo_height"] = "28px";
        praktijkinfo["flag-menu-margin-top"] = "2px";
        break;
      case "mondhuys":
        praktijkinfo["logo"] = mondhuys_logo;
        praktijkinfo["logo_height"] = "45px";
        praktijkinfo["flag-menu-margin-top"] = "8px";
        break;
      case "dental365amsterdam":
        praktijkinfo["logo"] = dental365_logo;
        praktijkinfo["logo_height"] = "30px";
        praktijkinfo["flag-menu-margin-top"] = "4px";
        break;
      default:
        praktijkinfo["logo"] = triadent_logo;
        praktijkinfo["logo_height"] = "28px";
        praktijkinfo["flag-menu-margin-top"] = "2px";
        break;
    }
  }

  return praktijkinfo;
}
