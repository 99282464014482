import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { maxWidth } from "@mui/system";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addFormData } from "../redux/actions";
import Submit from "../components/Submit";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TriadentStepper from "../components/TriadentStepper";
import QueryLink from "../components/QueryLink";
import { ErrorMessage } from "@hookform/error-message";
import BackSubmit from "../components/BackSubmit";
import { OnLastSubmitKlacht } from "../components/OnLastSubmit";

import OnNextSubmit from "../components/OnNextSubmit";
import Pagination from "../components/Pagination";
import { useOutletContext } from "react-router-dom";
import { extra_klacht_data } from "../types/klacht_data";
import { praktijkinfo } from "../types/praktijkinfo";
import { radiogroep_util } from "../utils/radiogroep_util";
import { uploadPhoto_utils } from "../utils/uploadPhoto_utils";
import { klachtCompProps } from "types/redux";
import { photoBox } from "utils/photoBox";
import { extra_notities } from "components/extra_notities";

let loading = false;
const setLoading = (bool: boolean) => {
  loading = bool;
};

interface AfgebrokenTandProps extends klachtCompProps {}

const AfgebrokenTand: React.FC<AfgebrokenTandProps> = (props) => {
  const navigate = useNavigate();
  const [foto, setFoto] = React.useState(props.formData.foto);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: props.formData,
  });
  const { t, i18n } = useTranslation();
  const praktijkInfo: praktijkinfo = useOutletContext();

  const prikkels = [
    t("kou"),
    t("warmte"),
    t("druk"),
    t("zoet"),
    t("zuur"),
    t("anders"),
  ];
  const locaties = [
    t("rechtsboven"),
    t("linksboven"),
    t("linksonder"),
    t("rechtsonder"),
    t("middenonder"),
    t("middenboven"),
    t("op onbekende plek"),
  ];
  const pijnstillers = [t("ibuprofen"), t("paracetamol"), t("anders")];

  const radiogroep = radiogroep_util(errors, control);

  const uploadPhoto = uploadPhoto_utils(setFoto);

  const onLastSubmit = OnLastSubmitKlacht(setLoading, foto, "Afgebroken tand");
  const extra_data: extra_klacht_data = { klacht: "Afgebroken tand" };
  if (foto) {
    extra_data["foto_blob_url"] = foto;
  }
  const onNextSubmit = OnNextSubmit(
    props.dispatch,
    extra_data,
    "/persoonsgegevens"
  );

  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];

  const onSubmit = onLastSubmit;

  const style = {
    width: "100%",
    maxWidth: 360,
    bgcolor: "background.paper",
  };

  return (
    <React.Fragment>
      <TriadentStepper onderdeel="klacht" />
      <ErrorMessage
        errors={errors}
        name={Object.keys(errors)[0]}
        render={({ message }) => (
          <p className={"error-message"}>
            {Object.keys(errors).map((e) => t(e)).length == 1
              ? `${t("Het veld")} ${
                  Object.keys(errors).map((e) => t(e))[0]
                } ${t("is verplicht")}`
              : `${t("De velden")} ${Object.keys(errors)
                  .map((e) => t(e))
                  .join(", ")
                  .replaceAll("_", " ")
                  .replace(/,(?=[^,]*$)/, " en")} ${t("zijn verplicht")}`}
          </p>
        )}
      />
      <Typography>{t("Vul de volgende gegevens in")}:</Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={-1} spacing={3}>
          <Grid item xs={12}>
            {/* <FormControl fullWidth variant="standard" error={!!errors.tand_locatie}>
        <InputLabel htmlFor="age-native-simple">{t("Wat is de locatie van de tand")}?</InputLabel>
        <Select
            // displayEmpty
            inputProps={register('tand_locatie', { required: "This is required message" })}
            native

        >
            <option value={"Rechtsboven"}>{t("Rechtsboven")}</option>
            <option value={"Middenboven"}>{t("Middenboven")}</option>
            <option value={"Linksboven"}>{t("Linksboven")}</option>
            <option value={"Linksonder"}>{t("Linksonder")}</option>
            <option value={"Middenonder"}>{t("Middenonder")}</option>
            <option value={"Rechtsonder"}>{t("Rechtsonder")}</option>
            <option value={"Op onbekende plek"}>{t("Onbekend")}</option>

        </Select>
    </FormControl> */}

            <FormGroup>
              <InputLabel htmlFor="age-native-simple">
                {t("Wat is de locatie van de tand")}?
              </InputLabel>

              <div>
                {[
                  "rechtsboven",
                  "linksboven",
                  "linksonder",
                  "rechtsonder",
                  "middenonder",
                  "middenboven",
                  "op onbekende plek",
                ].map((value, idx) => {
                  return (
                    <FormControlLabel
                      key={value}
                      control={
                        <Controller
                          // @ts-ignore
                          name={value + "_locatie"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => <Checkbox {...field} />}
                        />
                      }
                      label={
                        locaties[idx].charAt(0).toUpperCase() +
                        locaties[idx].slice(1)
                      }
                    />
                  );
                })}
              </div>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              component="fieldset"
              error={!!errors.tijd_geleden}
            >
              <FormLabel sx={{ mb: 2 }} component="legend">
                {t("Sinds wanneer heeft u last van een afgebroken stukje?")}
              </FormLabel>

              <Grid container spacing={1}>
                <Grid item xs={4} md={3}>
                  <TextField
                    variant="standard"
                    type="number"
                    InputProps={register("tijd_geleden", { required: true })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={4} md={2}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ minWidth: 120 }}
                    error={!!errors.tijd_eenheid}
                  >
                    <Select
                      inputProps={register("tijd_eenheid", { required: true })}
                      native
                    >
                      <option value={"dagen"}>{t("dagen")}</option>
                      <option value={"weken"}>{t("weken")}</option>
                      <option value={"maanden"}>{t("maanden")}</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          {is_spoed_praktijk && (
            <Grid item xs={12}>
              {radiogroep(
                ["vulling", "tand"],
                [t("vulling"), t("tand")],
                "vulling_tand",
                t("Is er een vulling of een tand afgebroken?")
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "pijn",
              t("Heeft u pijn?")
            )}
          </Grid>

          {watch("pijn") == "ja" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("ja"), t("nee")],
                "prikkels",
                t("Zijn er prikkels die de pijn erger maken?")
              )}
            </Grid>
          )}

          {watch("pijn") == "ja" && watch("prikkels") == "ja" && (
            <Grid mt={-1} item xs={12}>
              <FormGroup>
                <FormLabel>{t("Welke prikkels?")}</FormLabel>

                <div>
                  {["kou", "warmte", "druk", "zoet", "zuur", "anders"].map(
                    (value, idx) => {
                      return (
                        <FormControlLabel
                          key={value}
                          control={
                            <Controller
                              // @ts-ignore
                              name={value + "_prikkel"}
                              control={control}
                              rules={{ required: false }}
                              render={({ field }) => <Checkbox {...field} />}
                            />
                          }
                          label={
                            prikkels[idx].charAt(0).toUpperCase() +
                            prikkels[idx].slice(1)
                          }
                        />
                      );
                    }
                  )}
                </div>
              </FormGroup>
            </Grid>
          )}

          {watch("pijn") == "ja" &&
            watch("prikkels") == "ja" &&
            watch("anders_prikkel") && (
              <Grid mt={-4} item xs={12}>
                <TextField
                  fullWidth
                  error={!!errors.andere_prikkel}
                  variant="standard"
                  label={t("Andere prikkel(s)")}
                  InputProps={register("andere_prikkel", { required: false })}
                />
              </Grid>
            )}

          {watch("pijn") == "ja" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("ja"), t("nee")],
                "pijnstillers",
                t("Heeft u pijnstillers genomen?")
              )}
            </Grid>
          )}

          {watch("pijn") == "ja" && watch("pijnstillers") == "ja" && (
            <Grid mt={-1} item xs={12}>
              <FormGroup>
                <FormLabel>{t("Welke pijnstiller(s)?")}</FormLabel>

                <div>
                  {["ibuprofen", "paracetamol", "anders"].map((value, idx) => {
                    return (
                      <FormControlLabel
                        key={value}
                        control={
                          <Controller
                            // @ts-ignore
                            name={value + "_pijnstiller"}
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => <Checkbox {...field} />}
                          />
                        }
                        label={
                          pijnstillers[idx].charAt(0).toUpperCase() +
                          pijnstillers[idx].slice(1)
                        }
                      />
                    );
                  })}
                </div>
              </FormGroup>
            </Grid>
          )}
          {watch("pijn") == "ja" &&
            watch("pijnstillers") == "ja" &&
            watch("anders_pijnstiller") && (
              <Grid mt={-4} item xs={12}>
                <TextField
                  fullWidth
                  error={!!errors.andere_pijnstiller}
                  variant="standard"
                  label={t("Andere pijnstiller(s)")}
                  InputProps={register("andere_pijnstiller", {
                    required: false,
                  })}
                />
              </Grid>
            )}

          {watch("pijn") == "ja" && watch("pijnstillers") == "ja" && (
            <Grid item xs={12}>
              {radiogroep(
                ["ja", "nee"],
                [t("ja"), t("nee")],
                "helpen_pijnstillers",
                t("Helpen de pijnstillers?")
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "scherp",
              t("Voelt uw tand scherp?")
            )}
          </Grid>

          <Grid item xs={12}>
            {radiogroep(
              ["ja", "nee"],
              [t("ja"), t("nee")],
              "ongeluk",
              t("Is er sprake van een ongeluk?")
            )}
          </Grid>
          {is_spoed_praktijk && extra_notities(t, register, errors)}

          {photoBox(t, foto, uploadPhoto)}
        </Grid>

        <Pagination loading={loading} />
      </form>
    </React.Fragment>
  );
};

// export default AfgebrokenTand;
function mapStateToProps(state: any) {
  console.log(state.formData);
  return { formData: state.formData };
}

export default connect(mapStateToProps)(AfgebrokenTand);
