import React from 'react';
import { Box, Grid, Paper, Button, TextareaAutosize, useMediaQuery } from '@mui/material';
import tand from './../images/tand.png';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import TriadentStepper from '../components/TriadentStepper';
import { Link, useLocation } from 'react-router-dom';
import QueryLink from '../components/QueryLink';
import { ErrorMessage } from '@hookform/error-message';
import BackSubmit from '../components/BackSubmit';

let loading = false;
const setLoading = (bool: boolean) => {
    loading = bool;
}

interface Tandprops {

}

interface LogoButtonProps {
    categorie: string;
    src: string;
    title: string;
    text: string;
}

function Tand(props: Tandprops) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { t, i18n } = useTranslation();

    const LogoButton2 = LogoButton(isSmall)


    return (
        <React.Fragment>
            <TriadentStepper onderdeel="klacht_onderdeel" />

            <Typography component="h3" align="center">
                {t('Waar heeft uw klacht betrekking op?')}
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
                <Grid mt={0} container spacing={2} justifyContent="center" direction="row">
                    <LogoButton2 src="Afgebroken-tand" title={t("Afgebroken tand")} text={t("Er is een (stukje) tand/kies afgebroken") + '.'} categorie={'afgebroken_tand'}></LogoButton2>
                    <LogoButton2 src="Pijn" title={t("Pijn")} text={t("U heeft pijn aan een tand/kies") + '.'} categorie={'pijn_tand'}></LogoButton2>
                    <LogoButton2 src="Vulling" title={t("Hoge vulling")} text={t("Een vulling is te hoog") + '.'} categorie={'hoge_vulling'}></LogoButton2>
                    <LogoButton2 src="Losse" title={t("Losse Tand")} text={t("U heeft een loszittende tand") + '.'} categorie={'losse_tand'}></LogoButton2>

                </Grid>
            </Box>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', }} >

                <QueryLink to="/onderdelen">
                    <Button variant="outlined" sx={{
                        marginTop: 3,
                    }}>{t("Terug")}</Button>
                </QueryLink>


            </Box>
        </React.Fragment>
    )
}

export default Tand;

function LogoButton(isSmall: boolean) {
    return (props: LogoButtonProps) => {
        return <Grid container item xs={12} md={7} id="5">

            <QueryLink to={`/${props.categorie}`} style={{ display: 'contents' }}>
                <Button sx={{ padding: '0' }} fullWidth>

                    <Box sx={{ transition: '0.3s', '&:hover': { borderColor: 'rgba(30, 74, 113, 0.3)' }, padding: { xs: 2, md: 1 }, width: '100%', borderColor: 'text.primary', border: '1px solid #e8e8e8', borderRadius: '5%', display: 'flex', justifyItems: 'center' }}>
                        <Grid className="tand_icoon" item xs={5}>
                            <img src={require("./../images/iconen/" + props.src + ".png")} width={isSmall ? '50px' : '60px'} />
                        </Grid>
                        <Grid sx={{ 'display': 'flex' }} container direction="column" justifyContent="center" alignItems="center" item xs={5}>
                            <Typography sx={{ 'textTransform': 'none' }}>{props.title}</Typography>
                            <Typography variant='body2' sx={{ "textTransform": 'initial', 'color': 'black' }}>{props.text}</Typography>
                        </Grid>
                    </Box>

                </Button>
            </QueryLink>
        </Grid>;
    };
}
